/*
 * Name        : DFA ACF Google Maps
 * Version     : 0.2.1
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Builds Google Map from ACF fields
*/
var DFA = (function( d, $ )
{
	d.acf                     = d.acf || {};
	d.acf.google_maps         = {};
	d.acf.google_maps.new_map = null;
	d.acf.google_maps.$maps   = $( '.dfa--map-canvas' );

	d.add_acf_google_map_marker = function( $marker, m, marker_options )
	{
		var marker_html = $marker.html();
		var latlng = new google.maps.LatLng( $marker.attr( 'data-lat' ), $marker.attr( 'data-lng' ) );
		var default_marker_options = {
			position : latlng,
			map      : m,
		};

		var marker_settings = $.extend( {}, default_marker_options, marker_options );

		var marker = new google.maps.Marker( marker_settings );
		m.markers.push( marker );

		if ( marker_html )
		{
			marker.infoWindow = new google.maps.InfoWindow(
			{
				content : marker_html,
			} );

			google.maps.event.addListener( marker, 'click', function()
			{
				marker.infoWindow.open( m, marker );
			} );
		}
	};

	d.center_acf_google_map = function( m )
	{
		var bounds = new google.maps.LatLngBounds();

		$.each( m.markers, function( i, marker )
		{
			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
			bounds.extend( latlng );
		} );

		if ( 1 === m.markers.length )
		{
			m.setCenter( bounds.getCenter() );
			m.setZoom( 12 );
		}
		else
		{
			m.fitBounds( bounds );

			DFA.$window.on( 'resize', function( event )
			{
				DFA.waitForFinalEvent( m.fitBounds( bounds ), 100, 'DFA_fitBounds' );
			});
		}
	};

	d.make_acf_google_map = function( $el )
	{
		// Get the markers for this map
		var $markers     = $el.find( '.dfa-acf-map-marker' );
		var map_settings = {
			mapTypeControl : $el.data( 'maptypecontrol' ) || false,
			mapTypeId      : $el.data( 'maptype' )        || google.maps.MapTypeId.ROADMAP,
			scrollwheel    : $el.data( 'scrollwheel' )    || false,
			zoom           : $el.data( 'zoom' )           || 12,
		};

		// Set defaults
		var defaults = {
			center            : new google.maps.LatLng( 0, 0 ),
			draggable         : ( ( Modernizr && Modernizr.touch ) ? false : true ), // optional, but ideal for mobile devices
			jQuery_element    : $el,
			mapTypeControl    : map_settings.mapTypeControl,
			mapTypeId         : map_settings.mapTypeId,
			scrollwheel       : map_settings.scrollwheel,
			streetViewControl : ( ( Modernizr && Modernizr.touch ) ? false : true ),
			styles            : DFA.MAP_STYLES,
			zoom              : map_settings.zoom,
		};
		// Overload settings
		var settings = defaults;

		// Create map
		var map     = new google.maps.Map( $el[ 0 ], settings );
		map.markers = [];

		// Add markers
		$markers.each( function( index )
		{
			var $element = $( this );
			d.add_acf_google_map_marker( $element, map, $element.data( 'marker-options' ) );
		} );

		// Center map
		d.center_acf_google_map( map );

		// Return
		return map;
	};

	d.setup_acf_google_maps = function()
	{
		// Only proceed if we have google object and some maps
		if ( undefined !== typeof google && 0 < d.acf.google_maps.$maps.length )
		{
			d.acf.google_maps.$maps.each( function( index )
			{
				d.acf.google_maps.$maps[ index ] = d.make_acf_google_map( $( this ) );
			} );
		}
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
