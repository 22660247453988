/*
 * Name        : DFA Back to Top Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Adds a click event listener to `.back-to-top`
*/

var DFA = (function( d, $ )
{
	d.$back_to_top = $( '.back-to-top' );

	// For custom speed, adjust timing parameter
	// E.g., DFA.setup_back_to_top( 500 );
	d.setup_back_to_top = function( timing )
	{
		timing = typeof timing !== 'undefined' ? timing : 250;

		d.$back_to_top.click( function( event )
		{
			event.preventDefault();
			DFA.$html_and_body.animate( { scrollTop : 0 }, timing );
		} );
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
