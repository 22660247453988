/*
 * Name        : Access Health Louisiana Google Maps Settings
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Extends Deep Fried ACF Google Map module.
*/

var DFA = (function( d, $ )
{
	d.$map_home          = $( '.map-home' );
	d.map_home_reference = false;

	d.find_map_reference = function( $el )
	{
		var get_map_reference = false;

		DFA.acf.google_maps.$maps.each( function( index )
		{
			var map_reference = $( this );
			if ( undefined !== map_reference[ 0 ].jQuery_element && map_reference[ 0 ].jQuery_element.is( $el ) )
			{
				get_map_reference = map_reference[ 0 ];
			}
		} );

		return get_map_reference;
	};

	d.close_other_infowindows = function( map, markers, this_marker )
	{
		$.each( markers, function( i, marker )
		{
			if ( this_marker !== i )
			{
				marker.infoWindow.close();
			}
		} );
	};

	d.add_map_marker_options = function( $el )
	{
		$el.each( function( index )
		{
			var $this_map = $( this );
			$this_map.find( '.dfa-acf-map-marker' ).each( function( index )
			{
				var $marker = $( this );
				var map_marker_options = {};

				if ( undefined !== $marker.attr( 'data-marker-icon' ) )
				{
					map_marker_options.icon = {
						anchor     : new google.maps.Point( 12, 34 ),
						origin     : new google.maps.Point( 0, 0 ),
						size       : new google.maps.Size( 48, 68 ),
	          scaledSize : new google.maps.Size( 24, 34 ),
						url        : $marker.attr( 'data-marker-icon' ),
					};
				}

				$marker.data( 'marker-options', map_marker_options );
			} );
		} );
	};


	d.access_google_maps_setup = function()
	{
		DFA.$window.on( 'load', function( event )
		{
			d.map_home_reference = d.find_map_reference( d.$map_home );

			$.each( d.map_home_reference.markers, function( i, marker )
			{
				google.maps.event.addListener( marker, 'click', function()
				{
					d.close_other_infowindows( d.map_home_reference, d.map_home_reference.markers, i );
				} );
			} );
		} );

	};

  // Return local object
  return d;
}( DFA || {}, jQuery ) );
