/*
 * Name        : DFA Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Homepage Slider
*/

/* What is this?
 * IIFE: http://benalman.com/news/2010/11/immediately-invoked-function-expression/
 * Module Patterns: http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html
*/

// Import DFA module if it exists
// Import jQuery as $
var DFA = (function( d, $ )
{
	d.$home_slider = $( '.slides' );

	d.setup_home_slider = function()
	{
		if ( 0 === d.$home_slider.length )
		{
			return; // Bail early if no homepage-slider
		}

		d.$home_slider.bxSlider(
		{
			auto 	             :	true
			, controls 	       :	true
			, mode 	 	         :	'fade'
			, pager 	         :	true
			, pause			       :	8000
			, speed            : 	1000
			, adaptiveHeight	 : true
		} );
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
