/*
 * Name        : DFA Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Use the DFA Module (global variable) to declare variables
 *               for use inside _*.js partial files.
*/

/* What is this?
 * IIFE: http://benalman.com/news/2010/11/immediately-invoked-function-expression/
 * Module Patterns: http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html
*/

// Import DFA module if it exists
// Import jQuery as $
var DFA = (function( d, $ )
{
	// Attach properties/methods to local object
	// Properties
	d.$window           = $( window );
	d.$html             = $( 'html' );
	d.$body             = $( 'body' );
	d.$html_and_body    = d.$html.add( d.$body );

	// Use this to help detect media query states
	d.$menu_button      = $( '.menu-button' );

	// Methods
	d.is_retina = function()
	{
	  return window.devicePixelRatio > 1;
	};
	d.use_retina = function( $selector )
	{
		$selector.each( function( index )
		{
			var retina_src = $( this ).data( 'retina-src' );
			if ( retina_src )
			{
				$( this ).attr( 'src', retina_src );
			}
		} );
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
