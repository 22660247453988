/*
 * Name        : DFA Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Homepage Slider
 */

/* What is this?
 * IIFE: http://benalman.com/news/2010/11/immediately-invoked-function-expression/
 * Module Patterns: http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html
 */

// Import DFA module if it exists
// Import jQuery as $
var DFA = (function (d, $) {
  d.$review_slider = $(".Review-list");

  d.setup_review_slider = function () {
    if (0 === d.$review_slider.length) {
      return; // Bail early if no homepage-slider
    }

    d.$review_slider.bxSlider({
      auto: true,
      controls: true,
      pager: false,
      pause: 4000,
      speed: 1000,
    });
  };

  // Return local object
  return d;
})(DFA || {}, jQuery);
