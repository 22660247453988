/*
 * Name        : DFA Module : Parallax
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Enables parallax capabilities.
*/

var DFA = (function( d, $ )
{
  	d.previousScroll 	= 0;
  	d.scroll         	= parseInt( DFA.$window.scrollTop() );
	d.$parallax_img  	= $( '.js-parallax' );

	d.setup_parallax = function()
	{
	  // Parallax on non-touch devices
	  if ( false === Modernizr.touch )
	  {
		    // Parallax for Inline Images
		    if ( d.$parallax_img.length > 0 )
		    {
		      DFA.$window.scroll( function()
		      {
		        d.dfa_parallax( d.$parallax_img );
		      } );
		    }
	  }
	};

  d.dfa_parallax = function( img_container )
  {
    var offset = img_container.offset()
    ,   s      = DFA.$window.scrollTop()
    ,   speed  = 2.5 // Higher numbers are slower
    ,   adjust = ( s - offset.top ) / speed
    ,   img    = img_container.find( 'img' )
    ;

    if ( s > offset.top )
    {
      img.css( 'transform', 'translateY(' + adjust + 'px)' );
    }
    else
    {
      img.css( 'transform', 'translateY(0)' );
    }
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
