/*
 * Name        : DFA Slider
 * Version     : 0.4.1
 * Author      : Deep Fried Advertising
 * Author URL  : https://deepfried.com
 * Description : Setup Slider
*/

var DFA = (function( d, $ )
{
	d.$lgbtqSlider = $( '.lgbtq_header-service__container' );
	d.$lgbtqNav = $( '.lgbtq_header-service__nav' );
	d.$careerHeaderSlider = $( '.career_header-slider__container' );
	d.$careerJobSlider = $( '.career_jobs-card__container' );
	d.$careerHealthSlider = $( '.career-health-centers__container' );
	d.$careerHealthNav = $( '.career-health-centers__nav' );
	d.$careerSchoolSlider = $( '.career-health-centers--school__container' );
	d.$careerSchoolNav = $( '.career-health-centers--school__nav' );
	d.$careerCommunitySlider = $( '.career_community-slider__container' );
	d.$careerAccreditedSlider = $( '.career_accredited__container' );

	d.setup_lgbtq_slider = function()
	{
		// if ( 0 === d.$lgbtqSlider.length )
		// {
		// 	return; // Bail early if no homepage-slider
		// }

		d.$lgbtqSlider.slick(
		{
			autoplay: true,
			arrows: false,
			autoplay: false,
			cssEase: 'linear',
			dots: false,
			fade: true,
			infinite: true,
			pauseOnHover: false,
			swipe: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			asNavFor: '.lgbtq_header-service__nav'
		} );
		
		d.$lgbtqNav.slick(
		{
			autoplay: false,
			arrows:  true,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.lgbtq_header-service__container',
			variableWidth: true,
			dots: false,
			centerMode: true,
			focusOnSelect: true,
			responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 580,
				settings: {
					slidesToShow: 1,
				},
			},
		],
		} );

		d.$careerHeaderSlider.slick(
		{
			autoplay: true,
			arrows: true,
			autoplay: false,
			cssEase: 'linear',
			dots: true,
			fade: true,
			infinite: true,
			pauseOnHover: false,
			swipe: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 769,
					settings: {
						arrows: false,
					},
				},
			],
		} );

		d.$careerJobSlider.slick(
		{
			autoplay: false,
			arrows: true,
			dots: false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 769,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 580,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		} );
		
		d.$careerHealthNav.slick(
		{
			autoplay: false,
			arrows:  true,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.career-health-centers__container',
			variableWidth: true,
			dots: false,
			centerMode: true,
			focusOnSelect: true,
		} );

		d.$careerHealthSlider.slick(
		{
			autoplay: false,
			arrows: true,
			dots: true,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: '.career-health-centers__nav',
			responsive: [
				{
					breakpoint: 769,
					settings: {
						arrows: false,
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 580,
					settings: {
						arrows: false,
						slidesToShow: 1,
					},
				},
			],
		} );

		d.$careerSchoolNav.slick(
		{
			autoplay: false,
			arrows:  true,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.career-health-centers--school__container',
			variableWidth: true,
			dots: false,
			centerMode: true,
			focusOnSelect: true,
		} );

		d.$careerSchoolSlider.slick(
		{
			autoplay: false,
			arrows: true,
			dots: true,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: '.career-health-centers--school__nav',
			responsive: [
				{
					breakpoint: 769,
					settings: {
						arrows: false,
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 580,
					settings: {
						arrows: false,
						slidesToShow: 1,
					},
				},
			],
		} );

		d.$careerCommunitySlider.slick(
		{
			autoplay: true,
			arrows: false,
			autoplay: false,
			cssEase: 'linear',
			dots: true,
			fade: true,
			infinite: true,
			pauseOnHover: false,
			swipe: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		} );

		d.$careerAccreditedSlider.slick(
		{
			autoplay: false,
			arrows: false,
			dots: false,
			infinite: true,
			slidesToShow: 6,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 769,
					settings: {
						dots: true,
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 580,
					settings: {
						dots: true,
						slidesToShow: 2,
					},
				},
			],
		} );

	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );