/*
 * Name        : DFA Final Event Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Instead of firing on each event (scroll, resize, etc.), wait for the final event.
 *                http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed/#answer-4541963
*/

var DFA = (function( d, $ )
{
	d.waitForFinalEvent = function ()
	{
	  var timers = {};
	  return function ( callback, ms, uniqueId )
	  {
	    if ( ! uniqueId )
	    {
	      uniqueId = 'Don\'t call this twice without a uniqueId';
	    }
	    if ( timers[ uniqueId ] )
	    {
	      clearTimeout( timers[ uniqueId ] );
	    }
	    timers[ uniqueId ] = setTimeout( callback, ms );
	  };
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
