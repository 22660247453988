jQuery(document).ready(function ($) {
  DFA.setup_main_nav();
  DFA.setup_back_to_top();
  DFA.setup_home_slider();
  DFA.setup_lgbtq_slider();
  DFA.setup_parallax();
  DFA.add_map_marker_options($(".dfa--map-canvas")); // Needs to be above setup_acf_google_maps();
  DFA.setup_acf_google_maps();
  DFA.access_google_maps_setup();
  DFA.setup_review_slider();

  if (true === DFA.is_retina()) {
    var $img = $("img");
    DFA.use_retina($img);
  }

  $("article").fitVids();
});
