/*
 * Name        : DFA Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Scolling Tabs
 */

/* What is this?
 * IIFE: http://benalman.com/news/2010/11/immediately-invoked-function-expression/
 * Module Patterns: http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html
 */

// Import DFA module if it exists
// Import jQuery as $
var DFA = (function (d, $) {
  d.$scrolling_tabs = $(".nav-tabs");

  d.setup_scrolling_tabs = function () {
    // if (0 === d.$scrolling_tabs.length) {
    //   return; // Bail early if no scrolling tabs
    // }

    d.$scrolling_tabs.scrollingTabs();
  };

  // Return local object
  return d;
})(DFA || {}, jQuery);
