/*
 * Name        : DFA Main Nav Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Adds click event listeners to `.main-nav a`
*/

var DFA = (function( d, $ )
{
	d.$main_nav = $( '.main-nav' );

	d.setup_main_nav = function()
	{
		d.$main_nav.on( 'click', 'a', function( event )
		{
			// Store/cache the link as a local variable
			var this_nav_item_link = $( this );

			// Handle: a[href="#"]
			if ( '#' === this_nav_item_link.attr( 'href' ) )
			{
				event.preventDefault();
			}

			// Handle: a.menu-button
			if ( this_nav_item_link.is( DFA.$menu_button ) )
			{
				event.preventDefault();
				DFA.$html.toggleClass( 'main-nav-items-open' );
			}
		} );
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
