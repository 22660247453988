/*
 * Name        : DFA Module
 * Version     : 0.1.0
 * Author      : Deep Fried Advertising
 * Author URL  : http://deepfriedads.com
 * Description : Use the DFA Module (global variable) to declare variables
 *               for use inside _*.js partial files.
*/

/* What is this?
 * IIFE: http://benalman.com/news/2010/11/immediately-invoked-function-expression/
 * Module Patterns: http://www.adequatelygood.com/JavaScript-Module-Pattern-In-Depth.html
*/

// Import DFA module if it exists
// Import jQuery as $
var DFA = (function( d, $ )
{
	var sameheights = $( '.js-sameheights' );

	 d.$window.on( 'load orientationchange resize', function( event )
	 {
        dfa_min_height_maker( sameheights );
     } );

	// Homepage Spotlights have same height
	var dfa_return_min_height_maker = function( min_height_selector, reverse_logic )
	{
	  var highest_height = 0;
	  if ( reverse_logic && true === reverse_logic )
	  {
	    highest_height = 100000; // Some ridiculously high number to count backwards from
	  }

	  min_height_selector.each( function( index )
	  {
	    var my_height = jQuery( this ).outerHeight();
	    if ( reverse_logic && true === reverse_logic )
	    {
	      if ( my_height < highest_height )
	      {
	        highest_height = my_height;
	      }
	    }
	    else
	    {
	      if ( my_height > highest_height )
	      {
	        highest_height = my_height;
	      }
	    }
	  } );
	  return highest_height;
	};

	var dfa_min_height_maker = function( min_height_selector, reverse_logic )
	{
	  min_height_selector.each( function( index )
	  {
	    jQuery( this ).css( 'height', 'auto' );
	  } );
	  min_height_selector.height( dfa_return_min_height_maker( min_height_selector, reverse_logic ) );
	};

	// Return local object
	return d;
}( DFA || {}, jQuery ) );
